import React, { useState } from 'react';
import { TouchableOpacity, StyleSheet, View, Alert } from 'react-native';
import { CommonActions } from '@react-navigation/native';
import { Text } from 'react-native-paper';
import Background from '../components/Background';
import Logo from '../components/Logo';
import Header from '../components/Header';
import Button from '../components/Button';
import TextInput from '../components/TextInput';
import BackButton from '../components/BackButton';
import { theme } from '../core/theme';
import { emailValidator } from '../helpers/emailValidator';
import { passwordValidator } from '../helpers/passwordValidator';
import Axios from 'axios';
import CryptoJS from 'react-native-crypto-js';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useDispatch } from 'react-redux';
import { SetuserDetail } from '../store/actions/userAction';
import AwesomeAlert from 'react-native-awesome-alerts';

function LoginScreen({ navigation }) {
  const [showAlert, setshowAlert] = useState(false);
  const [email, setEmail] = useState({ value: '', error: '' });
  const [password, setPassword] = useState({ value: '', error: '' });
  const dispatch = useDispatch();

  const onLoginPressed = () => {
    const emailError = emailValidator(email.value);
    const passwordError = passwordValidator(password.value);
    if (emailError || passwordError) {
      setEmail({ ...email, error: emailError });
      setPassword({ ...password, error: passwordError });
      return;
    }
    Axios.get(
      `https://cryptosharkglobal.com:3020/api/getuserDetail/${email.value}/${password.value}`
    ).then((responce) => {
      if (responce.data.length > 0) {
        var localdata = {
          id: responce.data[0].id,
          email: responce.data[0].email,
          name: responce.data[0].name,
        };
        var ciphertext = CryptoJS.AES.encrypt(
          JSON.stringify(localdata),
          'ebay_large@830'
        ).toString();
        storeData(ciphertext);

        dispatch(SetuserDetail(localdata));
        navigation.reset({
          index: 0,
          routes: [{ name: 'BottomNav' }],
        });
      } else {
        setshowAlert(true);
      }
      // setPlanDetail(responce.data);
    });
  };
  const storeData = async (value) => {
    try {
      await AsyncStorage.setItem('@SocialHandeler', value);
    } catch (e) {}
  };
  const showAlertDialog = () => {
    setshowAlert(true);
  };

  const hideAlert = () => {
    setshowAlert(false);
  };

  return (
    <Background>
      {/* <BackButton goBack={navigation.goBack} /> */}
      <Logo />
      <Header>Welcome To QRCode APP</Header>
      <TextInput
        label='Email'
        returnKeyType='next'
        value={email.value}
        onChangeText={(text) => setEmail({ value: text, error: '' })}
        error={!!email.error}
        errorText={email.error}
        autoCapitalize='none'
        autoCompleteType='email'
        textContentType='emailAddress'
        keyboardType='email-address'
      />
      <TextInput
        label='Password'
        returnKeyType='done'
        value={password.value}
        onChangeText={(text) => setPassword({ value: text, error: '' })}
        error={!!password.error}
        errorText={password.error}
        secureTextEntry
      />
      <View style={styles.forgotPassword}>
        <TouchableOpacity onPress={() => navigation.navigate('ResetPassword')}>
          <Text style={styles.forgot}>Forgot your password?</Text>
        </TouchableOpacity>
      </View>
      <Button mode='contained' onPress={onLoginPressed}>
        Login
      </Button>
      <AwesomeAlert
        show={showAlert}
        showProgress={false}
        title='Invalid'
        message='Invalid Credentials..'
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showCancelButton={false}
        showConfirmButton={true}
        confirmText='Ok, Let me correct it'
        confirmButtonColor='#DD6B55'
        onConfirmPressed={hideAlert}
      />
    </Background>
  );
}
export default LoginScreen;

const styles = StyleSheet.create({
  forgotPassword: {
    width: '100%',
    alignItems: 'flex-end',
    marginBottom: 24,
  },
  row: {
    flexDirection: 'row',
    marginTop: 4,
  },
  forgot: {
    fontSize: 13,
    color: theme.colors.secondary,
  },
  link: {
    fontWeight: 'bold',
    color: theme.colors.primary,
  },
});
